import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import * as jwt from '@/services/jwt'

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    id: null,
    teamID: null,
    email: null,
    firstName: null,
    lastName: null,
    country: null,
    city: null,
    zipCode: null,
    address: null,
    phone: null,
    skype: null,
    status: 0,
    statusName: 'Active',
    role: null,
    avatar: null,
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const login = mapAuthProviders[rootState.settings.authProvider].login
      login(email, password).then(success => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT')
          Vue.prototype.$notification.success({
            message: 'Logged In',
            description: 'You have successfully logged in.',
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      const { teamName, country, email, password, firstName, lastName } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const register = mapAuthProviders[rootState.settings.authProvider].register
      register(teamName, country, email, password, firstName, lastName).then(success => {
        if (success.Status === 'Ok') {
          // dispatch('LOAD_CURRENT_ACCOUNT')
          router.push('/registration-pending')
          Vue.prototype.$notification.success({
            message: 'Successfully Registered',
            description: 'Your registration request is pending!',
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    LOAD_CURRENT_ACCOUNT({ commit, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      const currentAccount = mapAuthProviders[rootState.settings.authProvider].currentAccount
      currentAccount().then(response => {
        if (response) {
          const { Id, TeamId, Mail, FirstName, LastName, Country, City, ZipCode, Address, Phone, Skype, Status, StatusString, Role } = response
          commit('SET_STATE', {
            id: Id,
            teamID: TeamId,
            email: Mail,
            firstName: FirstName,
            lastName: LastName,
            country: Country,
            city: City,
            zipCode: ZipCode,
            address: Address,
            phone: Phone,
            skype: Skype,
            status: Status,
            statusName: StatusString,
            authorized: true,
            role: Role,
          })
        }
        commit('SET_STATE', {
          loading: false,
        })
      })
    },
    LOGOUT({ commit, rootState }) {
      const logout = mapAuthProviders[rootState.settings.authProvider].logout
      logout().then(() => {
        commit('SET_STATE', {
          id: null,
          teamID: null,
          email: null,
          firstName: null,
          lastName: null,
          country: null,
          city: null,
          zipCode: null,
          address: null,
          phone: null,
          skype: null,
          status: 0,
          statusName: null,
          authorized: false,
          loading: false,
        })
        router.push('/auth/login')
      })
    },
  },
  getters: {
    user: state => state,
  },
  setters: {

  },
}
