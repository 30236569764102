import Vue from 'vue'
import Vuex from 'vuex'
import { getIPLists, createIPList, updateIPList, changeIPListStatus, changeIPListGlobal } from '@/api/display/iplist'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_IPLIST(state, payload) {
      let ad = state.list.find(item => item.Id === parseInt(payload.Id))
      if (!ad) {
        ad = { Id: parseInt(payload.Id), ...payload }
        state.list.push(ad)
      } else {
        Object.assign(ad, payload)
      }
    },
  },
  actions: {
    LOAD_IPLISTS({ commit, dispatch, rootState }) {
      getIPLists().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_IPLIST({ commit, dispatch, rootState }, { payload, callback }) {
      createIPList(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'IP List Created',
              description: 'IP List has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new ad to store object
        dispatch('LOAD_IPLISTS')
      })
    },
    UPDATE_IPLIST({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateIPList({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_IPLIST', response.IpList)
          Vue.prototype.$notification.success({
            message: 'IP List Updated',
            description: 'IP List has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_GLOBAL({ commit, dispatch, rootState }, payload) {
      changeIPListGlobal(payload).then(response => {
        if (response) {
          commit('SET_IPLIST', response.IpList)
          Vue.prototype.$notification.success({
            message: (response.IpList.Global === 1 ? 'IP List Global status changed' : 'IP List Global status changed'),
            description: (response.IpList.Global === 1 ? 'IP List successfully changed.' : 'IP List global successfully changed.'),
          })
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeIPListStatus(payload).then(response => {
        if (response) {
          commit('SET_IPLIST', response.IpList)
          Vue.prototype.$notification.success({
            message: (response.IpList.Status === 2 ? 'IP List Deleted' : 'Status Changed'),
            description: (response.IpList.Status === 2 ? 'IP List successfully deleted.' : 'IP List status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getIpListData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getIpLists: (state) => {
      return state.list
    },
    getActiveIpLists: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
