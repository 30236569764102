import apiClient from '@/services/axios'

const END_POINT = '/usr/user'

/**
 * Update user account
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateUserPassword(user) {
  const preparedData = {
    OldPassword: user.OldPassword,
    Password: user.Password,
  }
  console.log(preparedData)
  return apiClient.post(END_POINT + '/update/own/password', { User: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update user account
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateUserAccount(user) {
  const parameters = {
    FirstName: { type: 'string', required: false },
    LastName: { type: 'string', required: false },
    Mail: { type: 'string', required: false, map: 'email' },
    Password: { type: 'string', required: false },
    Country: { type: 'string', required: false },
    City: { type: 'string', required: false },
    ZipCode: { type: 'string', required: false },
    Address: { type: 'string', required: false },
    Phone: { type: 'string', required: false },
    Skype: { type: 'string', required: false },
    Role: { type: 'int', required: false },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(user, parameters)
  return apiClient.post(END_POINT + '/update/own', { User: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get user's team info
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getUsers(id = null) {
  const payload = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new user under this users team
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createUser(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    FirstName: { type: 'string', required: true },
    LastName: { type: 'string', required: true },
    Mail: { type: 'string', required: true, map: 'email' },
    Password: { type: 'string', required: true },
    Country: { type: 'string', required: true },
    City: { type: 'string', required: false },
    ZipCode: { type: 'string', required: false },
    Address: { type: 'string', required: false },
    Phone: { type: 'string', required: false },
    Skype: { type: 'string', required: false },
    Role: { type: 'int', required: false },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { User: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update user data
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateUser(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: { type: 'int', required: true },
    FirstName: { type: 'string', required: false },
    LastName: { type: 'string', required: false },
    Password: { type: 'string', required: false },
    Country: { type: 'string', required: false },
    City: { type: 'string', required: false },
    ZipCode: { type: 'string', required: false },
    Address: { type: 'string', required: false },
    Phone: { type: 'string', required: false },
    Skype: { type: 'string', required: false },
    Role: { type: 'int', required: false },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/update', { User: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change user status
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeUserStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: { type: 'int', required: true },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { User: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
