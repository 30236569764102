import apiClient from '@/services/axios'
import Vue from 'vue'

const END_POINT = '/ortb/overlay'

/**
 * Get overlays
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getOverlays(id = null) {
  const payload = id != null ? { params: { Id: parseInt(id) } } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getOverlaysForAd(VideoadId) {
  return apiClient.get(END_POINT + '/get', {
    params: { VideoadId: VideoadId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new Overlay
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createOverlay(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    VideoadId: {
      type: 'int',
      required: true,
      map: 'videoadId',
    },
    Name: {
      type: 'string',
      required: true,
      map: 'overlayName',
    },
    StaticCreativeId: {
      type: 'int',
      required: false,
      map: 'staticCreativeId',
    },
    IframeUrl: {
      type: 'string',
      required: false,
      map: 'iframeUrl',
    },
    Html: {
      type: 'string',
      required: false,
      map: 'html',
    },
    Width: {
      type: 'int',
      required: true,
      map: 'width',
    },
    Height: {
      type: 'int',
      required: true,
      map: 'height',
    },
    MinDuration: {
      type: 'int',
      required: false,
    },
    MediaRating: {
      type: 'int',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Overlay: preparedData }).then(response => {
    if (response) {
      Vue.prototype.$notification.success({
        message: 'Overlay Created',
        description: `Overlay: ${response.data.Overlay.Name} has been successfully created.`,
      })
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateOverlay(payload) {
  // if (typeof payload !== 'object' || payload == null) {
  //   return false
  // }
  // Prepare and validate data
  // const preparedData = apiClient.prepare(payload)
  // if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
  //   return preparedData
  // }

  return apiClient.post(END_POINT + '/update', { Overlay: payload }).then(response => {
    // console.log(payload, 'payload')
    if (response) {
      // Vue.prototype.$notification.success({
      //   message: 'Overlay Updated.',
      //   description: `Overlay: ${payload.Name} Updated.`,
      // })
      return response.data
    }
    return false
  }).catch(err => {
    console.log(err)
    Vue.prototype.$notification.error({
      message: 'Invalid Data.',
    })
  })
}

/**
 * Change Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeOverlayStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Overlay: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function deleteOverlay(id) {
  return apiClient.post(END_POINT + '/delete', {
    Overlay: { Id: id },
  }).then(response => {
    if (response) {
      Vue.prototype.$notification.success({
        message: 'Overlay Creative Deleted',
        description: `Overlay: ${response.data.Overlay.Name} has been successfully deleted.`,
      })
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Add
 *
 * @param {int} id - add id
 * @returns {Promise} - Request promise
 */
export async function cloneOverlay(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Overlay: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
