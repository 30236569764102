import Vue from 'vue'
import Vuex from 'vuex'
import { getUsers, createUser, updateUser, changeUserStatus } from '@/api/user'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_USER(state, payload) {
      const user = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(user, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_USERS({ commit, dispatch, rootState }, id = null) {
      getUsers(id).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_USER({ commit, dispatch, rootState }, { payload, callback = null }) {
      createUser(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'User Created',
              description: 'User has been successfully created.',
            })
            if (callback != null) {
              callback()
            }
          }
        }
        // TODO: add new user to store object
        dispatch('LOAD_USERS')
      })
    },
    UPDATE_USER({ commit, dispatch, rootState }, { id, payload, callback = null }) {
      updateUser({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_USER', response.User)
          Vue.prototype.$notification.success({
            message: 'User Updated',
            description: 'User has been successfully updated.',
          })
          if (callback != null) {
            callback()
          }
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeUserStatus(payload).then(response => {
        if (response) {
          commit('SET_USER', response.User)
          Vue.prototype.$notification.success({
            message: (response.User.Status === 2 ? 'User Deleted' : 'Status Changed'),
            description: (response.User.Status === 2 ? 'User successfully deleted.' : 'User status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getUser: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getUsers: (state) => {
      return state.list
    },
    getActiveUsers: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
