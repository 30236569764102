import Vue from 'vue'
import Vuex from 'vuex'
import { getCountries, getIP, getOSs, getBrowsers, getServers } from '@/api/info'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    ip: null,
    oss: [],
    browsers: [],
    countries: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_USERIP({ commit, dispatch, rootState }) {
      getIP().then(response => {
        if (response) {
          commit('SET_STATE', {
            ip: response,
          })
        }
      })
    },
    LOAD_COUNTRIES({ commit, dispatch, rootState }) {
      return getCountries().then(response => {
        if (response) {
          response.sort((a, b) => a.Country.localeCompare(b.Country))
          commit('SET_STATE', {
            countries: response,
          })
          return response
        }
        return false
      })
    },
    LOAD_OSS({ commit, dispatch, rootState }) {
      getOSs().then(response => {
        if (response) {
          commit('SET_STATE', {
            oss: response,
          })
        }
      })
    },
    LOAD_BROWSERS({ commit, dispatch, rootState }) {
      getBrowsers().then(response => {
        if (response) {
          commit('SET_STATE', {
            browsers: response,
          })
        }
      })
    },
    LOAD_SERVERS({ commit, dispatch, rootState }) {
      getServers().then(response => {
        if (response) {
          commit('SET_STATE', {
            servers: response,
          })
        }
      })
    },
  },
  getters: {
    countryName: state => (cc) => {
      const records = state.countries.filter(e => e.CC === cc)
      if (typeof records[0] === 'undefined') { return '' }
      if (typeof records[0].Country === 'undefined') { return '' }
      return records[0].Country
    },
    user: state => state,
  },
}
