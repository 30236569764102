import Vue from 'vue'
import Vuex from 'vuex'
import { getAdvertisers, createAdvertiser, updateAdvertiser, changeAdvertiserStatus, getAdvertisersForUser } from '@/api/display/advertiser'
import { getAdvertisersShort } from '../../../api/display/advertiser'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_ADVERTISER(state, payload) {
      const advertiser = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(advertiser, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_ADVERTISERS({ commit, dispatch, rootState }, payload) {
      let params = null
      if (payload) {
        params = payload.AdvertiserId
      }
      getAdvertisers(params).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ADVERTISERS_SHORT({ commit, dispatch, rootState }, payload) {
      let params = null
      if (payload) {
        params = payload.AdvertiserId
      }
      getAdvertisersShort(params).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },

    LOAD_ADVERTISERS_FOR_USER({ commit, dispatch, rootState }, payload) {
      getAdvertisersForUser(payload.AccManagerId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },

    CREATE_ADVERTISER({ commit, dispatch, rootState }, { payload, callback }) {
      createAdvertiser(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Advertiser Created',
              description: 'Advertiser has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new advertiser to store object
        dispatch('LOAD_ADVERTISERS')
      })
    },
    UPDATE_ADVERTISER({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateAdvertiser({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_ADVERTISER', response.Advertiser)
          Vue.prototype.$notification.success({
            message: 'Advertiser Updated',
            description: 'Advertiser has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeAdvertiserStatus(payload).then(response => {
        if (response) {
          commit('SET_ADVERTISER', response.Advertiser)
          Vue.prototype.$notification.success({
            message: (response.Advertiser.Status === 2 ? 'Advertiser Deleted' : 'Status Changed'),
            description: (response.Advertiser.Status === 2 ? 'Advertiser successfully deleted.' : 'Advertiser status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getAdvertiser: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getAdvertisers: (state) => {
      return state.list
    },
    getActiveAdvertisers: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
