import Vue from 'vue'
import Vuex from 'vuex'
import { getKeywordLists, createKeywordList, updateKeywordList, changeKeywordListStatus } from '@/api/xml/kwlist'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_KWLIST(state, payload) {
      const ad = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(ad, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_KWLISTS({ commit, dispatch, rootState }) {
      getKeywordLists().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_KWLIST({ commit, dispatch, rootState }, { payload, callback }) {
      createKeywordList(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Keyword List Created',
              description: 'Keyword List has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new ad to store object
        dispatch('LOAD_KWLISTS')
      })
    },
    UPDATE_KWLIST({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateKeywordList({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_KWLIST', response.KeywordList)
          Vue.prototype.$notification.success({
            message: 'Keyword List Updated',
            description: 'Keyword List  has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeKeywordListStatus(payload).then(response => {
        if (response) {
          commit('SET_KWLIST', response.KeywordList)
          Vue.prototype.$notification.success({
            message: (response.KeywordList.Status === 2 ? 'Keyword List Deleted' : 'Status Changed'),
            description: (response.KeywordList.Status === 2 ? 'Keyword List successfully deleted.' : 'Keyword List status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getKeywordListData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getkwlistLists: (state) => {
      return state.list
    },
    getActiveKwLists: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
