import apiClient from '@/services/axios'

const END_POINT = '/xml/iplist'

/**
 * Get IP lists
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getIPLists(id = null) {
  const payload = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get IP List upload path
 */
export function getActionURL() {
  return apiClient.defaults.baseURL + END_POINT + '/add'
}

/**
 * Create new Ad
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createIPList(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    File: {
      type: 'file',
      required: true,
      map: 'campaign',
    },
    // Name: {
    //   type: 'string',
    //   required: true,
    // },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Ad: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateIPList(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Name: {
      type: 'string',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/update', { IpList: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeIPListGlobal(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Global: {
      type: 'int',
      required: true,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/update', { IpList: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function changeIPListStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { IpList: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function downloadIpListFile(id, name) {
  apiClient.get(END_POINT + '/download?Id=' + id, { responseType: 'blob' }).then(response => {
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      const extensionCheck = name.slice(-4)
      if (extensionCheck === '.txt') {
        link.download = name
      } else {
        link.download = name + '.txt'
      }
      link.click()
      URL.revokeObjectURL(link.href)
    }
    return false
  }).catch(err => console.log(err))
}
