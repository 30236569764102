const GlobalFunctionsPlugin = {
  install(Vue) {
    Vue.prototype.$formatCurrency = function (value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(value)
    }
    Vue.prototype.$formatNumber = function (value) {
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value)
    }
  },
}

export default GlobalFunctionsPlugin
