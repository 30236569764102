<template>
  <a-dropdown @visibleChange="markNotificationsRead" :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-badge :count="count">
        <i class="fe fe-bell" :class="$style.icon"></i>
      </a-badge>
    </div>
    <div slot="overlay">
      <div class="card cui__utils__shadow width-350">
        <div class="card-body pb-2">
          <list3 v-if="notifications.length !== 0" :notifications="notifications" :seen="seen" />
          <p v-else>There are no notifications.</p>
          <a-button v-if="notifications.length !== 0" @click="reroute" type="link" block>See All
            Notifications</a-button>
        </div>
      </div>
    </div>
  </a-dropdown>
</template>

<script>
import List3 from '@/components/kit/widgets/Lists/3'
import { getRecentNotifications, changeNotificationsStatus } from '@/api/notification'

export default {
  data() {
    return {
      notifications: [],
      seen: false,
      count: null,
      timer: null,
    }
  },
  components: {
    List3,
  },
  methods: {
    reroute() {
      this.markNotificationsRead()
      this.$router.push('/user/notifications')
    },
    markNotificationsRead(param) {
      if (!param) {
        changeNotificationsStatus().then(
          (res) => {
            if (res) {
              this.notifications.forEach(el => {
                el.Seen = 1
              })
              this.count = 0
            }
          },
        )
      }
    },
  },
  mounted() {
    getRecentNotifications()
      .then((res) => {
        this.notifications = res.Notifications
        this.count = res.NewCount
      })
      .catch((err) => {
        console.log(err)
      })
    this.timer = setInterval(() => {
      getRecentNotifications()
        .then((res) => {
          this.notifications = res.data.Notifications
          this.count = res.data.NewCount
        })
        .catch((err) => {
          console.log(err)
        })
    }, 3600000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
