<template>
  <a-menu-item :key="menuInfo.key" v-bind="$props" v-on="$listeners" :disabled="menuInfo.disabled">
    <router-link class="z-neutral" v-if="menuInfo.url" :to="{ name: menuInfo.url }">
      <span v-if="!hasButton" :class="styles.title">{{ menuInfo.title }}</span>
      <span v-else class="justify-between" :class="styles.title"><p>{{ menuInfo.title }}</p>
        <router-link class="add-button" @click.stop :to="{ name: menuInfo.addUrl }">
          <i v-b-tooltip.hover :title="menuInfo.tooltip" class="web material-icons-outlined" style="fontSize: 115%;">add</i>
        </router-link>
      </span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.class]">{{ menuInfo.icon }}</i>
    </router-link>
    <span v-if="!menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
    </span>
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: Object,
    styles: Object,
    hasButton: Boolean,
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
  .add-button {
    margin-bottom: 5px;
    border: none;
    background: none;
    font-size: 110%;
    font-style: bold;
    width: max-content;
    position: relative;
    z-index: 10;
    color: rgb(172, 172, 172);
  }
  .add-button :hover {
    -webkit-animation: fadein 0.6s;
    -moz-animation: fadein 0.6s;
    -ms-animation: fadein 0.6s;
    -o-animation: fadein 0.6s;
    animation: fadein 0.6s;
    background: #3474ff;
    /* opacity: .5; */
    color: rgb(206, 206, 206);
    border-radius: 5px;
  }
  .justify-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* .z-neutral {
    position: relative;
    z-index: 0;
    width: max;
  } */
  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
</style>
