import apiClient from '@/services/axios'
import Vue from 'vue'

const END_POINT = '/ortb/nativeasset'

/**
 * Get native assets
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getNativeAssets(id = null) {
  const payload = id != null ? { params: { Id: parseInt(id) } } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getNativeAssetsForAd(VideoadId) {
  return apiClient.get(END_POINT + '/get', {
    params: { VideoadId: VideoadId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new Companion
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createNativeAsset(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  // const parameters = {
  //   NativeadId: {
  //     type: 'int',
  //     required: true,
  //   },
  //   Type: {
  //     type: 'int',
  //     required: true,
  //   },
  //   Name: {
  //     type: 'string',
  //     required: true,
  //   },
  //   LinkUrl: {
  //     type: 'string',
  //     required: false,
  //   },
  //   LinkClicktrackers: {
  //     required: false,
  //   },
  //   LinkFallback: {
  //     type: 'string',
  //     required: false,
  //   },
  //   Required: {
  //     type: 'int',
  //     required: false,
  //   },
  //   TitleText: {
  //     type: 'string',
  //     required: (payload.Type === 0),
  //   },
  //   ImgType: {
  //     type: 'int',
  //     required: (payload.Type === 1),
  //   },
  //   ImgCreativeId: {
  //     type: 'int',
  //     required: (payload.Type === 1),
  //   },
  //   VideoCreativeId: {
  //     type: 'int',
  //     required: (payload.Type === 2),
  //   },
  //   DataType: {
  //     type: 'int',
  //     required: (payload.Type === 3),
  //   },
  //   DataLabel: {
  //     type: 'string',
  //     required: false,
  //   },
  //   DataValue: {
  //     type: 'string',
  //     required: (payload.Type === 3),
  //   },
  // }
  // Prepare and validate data
  // const preparedData = apiClient.prepare(payload, parameters)
  // if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
  //   return preparedData
  // }
  return apiClient.post(END_POINT + '/add', { Nativeasset: payload }).then(response => {
    if (response) {
      Vue.prototype.$notification.success({
        message: 'Native Asset Created',
        description: `Native Asset: ${response.data.Nativeasset.Name} has been successfully created.`,
      })
      return response.data
    }
    return false
  }).catch(err => {
    Vue.prototype.$notification.warning({
      message: err.code,
      description: err.message,
    })
    console.log(err)
  })
}

/**
 * Update Native Asset
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateNativeAsset(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Name: {
      type: 'string',
      required: true,
    },
    LinkUrl: {
      type: 'string',
      required: false,
    },
    LinkClicktrackers: {
      required: false,
    },
    LinkFallback: {
      type: 'string',
      required: false,
    },
    Required: {
      type: 'int',
      required: false,
    },
    TitleText: {
      type: 'string',
      required: (payload.Type === 0),
    },
    ImgType: {
      type: 'int',
      required: (payload.Type === 1),
    },
    ImgCreativeId: {
      type: 'int',
      required: (payload.Type === 1),
    },
    VideoCreativeId: {
      type: 'int',
      required: (payload.Type === 2),
    },
    DataType: {
      type: 'int',
      required: (payload.Type === 3),
    },
    DataLabel: {
      type: 'string',
      required: false,
    },
    DataValue: {
      type: 'string',
      required: (payload.Type === 3),
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }
  // console.log(preparedData)
  // return false
  return apiClient.post(END_POINT + '/update', { Nativeasset: preparedData }).then(response => {
    if (response) {
      Vue.prototype.$notification.success({
        message: 'Native Asset Updated.',
        description: `Native Asset: ${response.data.Nativeasset.Name} Updated.`,
      })
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */

export async function deleteNativeAsset(id) {
  return apiClient.post(END_POINT + '/delete', {
    Nativeasset: { Id: id },
  }).then(response => {
    if (response) {
      Vue.prototype.$notification.success({
        message: 'Native Asset Deleted',
        description: `Native Asset: ${response.data.Nativeasset.Name} has been successfully deleted.`,
      })
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
