import apiClient from '@/services/axios'
import store from 'store'

export async function login(email, password) {
  return apiClient
    .post('/auth/login', {
      Username: email,
      Password: password,
    })
    .then(response => {
      if (response.data.Status === 'Ok') {
        const accessToken = response.data.AccessToken
        if (accessToken) {
          store.set('accessToken', accessToken)
          store.set('eventLogout', false)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(teamName, country, email, password, firstName, lastName) {
  return apiClient
    .post('/xml/registration/add', {
      Registration: {
        TeamName: teamName,
        Mail: email,
        Password: password,
        FirstName: firstName,
        LastName: lastName,
        Country: country,
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/auth/account')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken')
      store.set('eventLogout', true)
      return true
    })
    .catch(err => console.log(err))
}
