import apiClient from '@/services/axios'

const END_POINT = '/usr/notification/'

/**
 * Get IP
 *
 * @returns {Promise} - Request promise
 */
export async function getNotifications() {
  return apiClient.get(END_POINT + 'get').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getRecentNotifications() {
  return apiClient.get(END_POINT + 'recent/get').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function changeNotificationsStatus() {
  return apiClient.get(END_POINT + 'see').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
