import Vue from 'vue'
import Vuex from 'vuex'
import { getNativeAds, createNativeAd, updateNativeAd, changeNativeAdStatus, getNativeAdsForCampaign, getNativeAdsForUserAndCampaign } from '@/api/display/nativead'
import { getNativeAdsShort } from '../../../api/display/nativead'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_AD(state, payload) {
      const ad = state.list.find(item => item.Id === parseInt(payload.Id))
      if (ad !== null && ad !== undefined) {
        Object.assign(ad, {
          ...payload,
        })
      }
    },
  },
  actions: {
    LOAD_ADS_FOR_CAMPAIGN({ commit, dispatch, rootState }, payload) {
      getNativeAdsForCampaign(payload.CampaignId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ADS_FOR_USER_CAMP({ commit, dispatch, rootState }, payload) {
      getNativeAdsForUserAndCampaign(payload.AccManagerId, payload.CampaignId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ADS({ commit, dispatch, rootState }) {
      return getNativeAds().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
        return true
      })
    },
    LOAD_ADS_SHORT({ commit, dispatch, rootState }) {
      return getNativeAdsShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
        return true
      })
    },
    CREATE_AD({ commit, dispatch, rootState }, { payload, callback }) {
      createNativeAd(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Native Ad Created',
              description: 'Native Ad has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new ad to store object
        dispatch('LOAD_ADS')
      })
    },
    UPDATE_AD({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateNativeAd({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_AD', response.Nativead)
          Vue.prototype.$notification.success({
            message: 'Native Ad Updated',
            description: 'Native Ad has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeNativeAdStatus(payload).then(response => {
        if (response) {
          commit('SET_AD', response.Nativead)
          Vue.prototype.$notification.success({
            message: (response.Nativead.Status === 2 ? 'Native Ad Deleted' : 'Status Changed'),
            description: (response.Nativead.Status === 2 ? 'Native Ad successfully deleted.' : 'Native Ad status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getNativeAdData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getNativeAds: (state) => {
      return state.list
    },
    getActiveNativeAds: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
