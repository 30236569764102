import Vue from 'vue'
import Vuex from 'vuex'
import { getIcons } from '@/api/display/icon'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_ICON(state, payload) {
      const ad = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(ad, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_ICONS({ commit, dispatch, rootState }) {
      getIcons().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
  },
  getters: {
    getIconData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getIcons: (state) => {
      return state.list
    },
    getActiveIcons: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
