import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'

const apiClient = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? window.location.origin + '/api' : 'https://consoledev.adelion.com/api',
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  let message = error.response.data.Error
  if (message === "Filter field 'Contains' contains illegal character(s).") {
    message = 'Search field has illegal character(s).'
  }
  // Redirect if token expires
  if (response.status === 401 && response.config.url !== '/auth/account' && response.config.url !== '/') window.location.href = '/'
  // Notify
  if (data && response.status !== 401) {
    notification.warning({
      message: message,
    })
  }
})

// Validate parameter data
apiClient.prepare = function(data, parameters) {
  if (data != null && typeof data !== 'object') {
    return { type: 'error', message: 'No data provided!' }
  }
  const payload = {}
  for (const param in parameters) {
    for (const key in data) {
      if (param.toLowerCase() === key.toLowerCase() || (typeof parameters[param].map !== 'undefined' && parameters[param].map === key)) {
        if (data[key] === null || data[key] === undefined) {
          continue
        }
        switch (parameters[param].type) {
          case 'int':
            payload[param] = parseInt(data[key])
            break
          case 'float':
            payload[param] = parseFloat(data[key])
            break
          default:
            payload[param] = data[key]
        }
        break
      }
    }
    if (parameters[param].required && !Object.prototype.hasOwnProperty.call(payload, param)) {
      return { type: 'error', message: `Required parameter ${param} missing.` }
    }
  }
  return payload
}

export default apiClient
