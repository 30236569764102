import Vue from 'vue'
import Vuex from 'vuex'
import { getRemoteVideoZones, createRemoteVideoZone, updateRemoteVideoZone, changeRemoteVideoZoneStatus, getRemoteVideoZonesForAdvertiser, getRemoteVideoZonesForUserAndAdvertiser } from '@/api/display/remotevideozone'
import { getRemoteVideoZonesShort } from '../../../api/display/remotevideozone'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_ZONE(state, payload) {
      const remotevideozone = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(remotevideozone, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_ZONES_FOR_ADVERTISER({ commit, dispatch, rootState }, payload) {
      getRemoteVideoZonesForAdvertiser(payload.AdvertiserId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ZONES_FOR_USER_ADV({ commit, dispatch, rootState }, payload) {
      getRemoteVideoZonesForUserAndAdvertiser(payload.AccManagerId, payload.AdvertiserId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ZONES({ commit, dispatch, rootState }) {
      getRemoteVideoZones().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ZONES_SHORT({ commit, dispatch, rootState }) {
      getRemoteVideoZonesShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_ZONE({ commit, dispatch, rootState }, { payload, callback }) {
      createRemoteVideoZone(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Remote Video Created',
              description: 'Remote Video Zone has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new ad to store object
        dispatch('LOAD_ZONES')
      })
    },
    UPDATE_ZONE({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateRemoteVideoZone({ id, ...payload }).then(response => {
        if (response) {
          // commit('SET_ZONE', response.Remotevideozone)
          Vue.prototype.$notification.success({
            message: 'Remote Video Zone Updated',
            description: 'Remote Video Zone has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeRemoteVideoZoneStatus(payload).then(response => {
        if (response) {
          commit('SET_ZONE', response.Remotevideozone)
          Vue.prototype.$notification.success({
            message: (response.Remotevideozone.Status === 2 ? 'Remote Video Zone Deleted' : 'Status Changed'),
            description: (response.Remotevideozone.Status === 2 ? 'Remote Video Zone successfully deleted.' : 'Remote Video Zone status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getRemoteVideoZoneData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getRemoteVideoZones: (state) => {
      return state.list
    },
    getActiveRemoteVideoZones: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
