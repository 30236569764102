import apiClient from '@/services/axios'

const END_POINT = '/ortb/remotevideozone'

/**
 * Get remote video zones
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
// export async function getRemoteVideoZones(id = null) {
//   const payload = id != null ? { Id: id } : null

//   return apiClient.get(END_POINT + '/get', payload).then(response => {
//     if (response) {
//       return response.data
//     }
//     return false
//   }).catch(err => console.log(err))
// }
export async function getRemoteVideoZones(id = null) {
  const payload = id != null ? { params: { Id: parseInt(id) } } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getRemoteVideoZonesForAdvertiser(advertiserId) {
  return apiClient.get(END_POINT + '/get', {
    params: { AdvertiserId: advertiserId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getRemoteVideoZonesShort() {
  return apiClient.get(END_POINT + '/get/short', {
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getRemoteVideoZonesForUserAndAdvertiser(accManagerId, advertiserId) {
  let paramAccManagerId = accManagerId
  let paramAdvertiserId = advertiserId
  if (!paramAccManagerId) {
    paramAccManagerId = '0'
  } else if (!paramAdvertiserId) {
    paramAdvertiserId = '0'
  }
  return apiClient.get(END_POINT + '/get', {
    params: { AccManagerId: accManagerId, AdvertiserId: advertiserId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new zone
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createRemoteVideoZone(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    AdvertiserId: {
      type: 'int',
      required: true,
      map: 'advertiser',
    },
    Name: {
      type: 'string',
      required: true,
    },
    Description: {
      map: 'description',
      type: 'string',
      required: false,
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
      map: 'clicksDailyLimit',
    },
    ZoneUrl: {
      type: 'string',
      required: true,
      map: 'zoneUrl',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Remotevideozone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Remote Video Zone
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateRemoteVideoZone(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    Name: {
      map: 'name',
      type: 'string',
      required: true,
    },
    Description: {
      map: 'description',
      type: 'string',
      required: false,
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ClicksDailyLimit: {
      type: 'int',
      required: false,
      map: 'clicksDailyLimit',
    },
    ZoneUrl: {
      type: 'string',
      required: false,
      map: 'zoneUrl',
    },
    Videozones: {
      type: 'intArray',
      required: false,
    },
    Rtbzones: {
      type: 'intArray',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }
  // console.log(preparedData)
  // return false
  return apiClient.post(END_POINT + '/update', { Remotevideozone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change Ad
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeRemoteVideoZoneStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Remotevideozone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Add
 *
 * @param {int} id - add id
 * @returns {Promise} - Request promise
 */
export async function cloneRemoteVideoZone(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Remotevideozone: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
