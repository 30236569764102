import Vue from 'vue'
import Vuex from 'vuex'
import { getDomainLists, createDomainList, updateDomainList, changeDomainListStatus, changeDomainListGlobal } from '@/api/display/domainlist'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_DOMAINLIST(state, payload) {
      let ad = state.list.find(item => item.Id === parseInt(payload.Id))
      if (!ad) {
        ad = { Id: parseInt(payload.Id), ...payload }
        state.list.push(ad)
      } else {
        Object.assign(ad, payload)
      }
    },
  },
  actions: {
    LOAD_DOMAINLISTS({ commit, dispatch, rootState }) {
      getDomainLists().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_DOMAINLIST({ commit, dispatch, rootState }, { payload, callback }) {
      createDomainList(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Domain List Created',
              description: 'Domain List has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new ad to store object
        dispatch('LOAD_DOMAINLISTS')
      })
    },
    UPDATE_DOMAINLIST({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateDomainList({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_DOMAINLIST', response.DomainList)
          Vue.prototype.$notification.success({
            message: 'Domain List Updated',
            description: 'Domain List has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_GLOBAL({ commit, dispatch, rootState }, payload) {
      changeDomainListGlobal(payload).then(response => {
        if (response) {
          commit('SET_DOMAINLIST', response.DomainList)
          Vue.prototype.$notification.success({
            message: (response.DomainList.Global === 1 ? 'Domain List Global status changed' : 'Domain List Global status changed'),
            description: (response.DomainList.Global === 1 ? 'Domain List successfully changed.' : 'Domain List global successfully changed.'),
          })
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeDomainListStatus(payload).then(response => {
        if (response) {
          commit('SET_DOMAINLIST', response.DomainList)
          Vue.prototype.$notification.success({
            message: (response.DomainList.Status === 2 ? 'Domain List Deleted' : 'Status Changed'),
            description: (response.DomainList.Status === 2 ? 'Domain List successfully deleted.' : 'Domain List status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getDomainListData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getDomainLists: (state) => {
      return state.list
    },
    getActiveDomainLists: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
