<template>
  <div>
    <ul class="list-unstyled">
      <li v-for="notification in notifications" :key="notification.Id" :class="$style.item">
        <a :class="$style.itemLink">
          <div :style="{ 'font-size': '14px', 'text-align': 'center', 'width': '6rem', 'padding-right': '1rem' }" :class="`${notification.Seen === 0 ? 'text-blue' : 'text-gray'}`" class="text-center font-weight-bold">
            {{ convertTimestamp(notification.TimeCreated) }}
          </div>
          <!-- <div v-if="notification.Seen === 1" :style="{ 'font-size': '100%' }" :class="$style.itemMeta" class="font-weight-bold text-gray text-center">
            {{ convertTimestamp(notification.TimeCreated) }}
          </div> -->
          <div class="mr-3">
            <div :class="`${notification.Seen === 0 ? 'text-blue font-weight-bold' : 'text-gray'}`" class="">{{ notification.Message }}</div>
          </div>
        </a>
        <a-divider v-if="notification !== notifications[notifications.length - 1]" class="divider-visibility">
        </a-divider>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'KitList3',
  data() {
    return {
    }
  },
  props: ['notifications', 'seen'],
  methods: {
    convertTimestamp: function (timestamp) {
      const timeStamp = timestamp + '000'
      const date = new Date(parseInt(timeStamp))
      const fullTime = date.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      }) + ' ' + date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      return fullTime
    },
  },
}
</script>
<style lang="scss" module>
.item-wrapper {
  display: flex;
  align-items: flex-end;
}
@import './style.module.scss';

divider-visibility {
  opacity: 100%;
}

.divider-visibility:last-child {
  opacity: 0%;
}
</style>
