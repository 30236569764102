import Vue from 'vue'
import Vuex from 'vuex'
import { getCampaigns, createCampaign, updateCampaign, changeCampaignStatus } from '@/api/xml/campaign'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_CAMPAIGN(state, payload) {
      const campaign = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(campaign, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_CAMPAIGNS({ commit, dispatch, rootState }) {
      getCampaigns().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_CAMPAIGN({ commit, dispatch, rootState }, { payload, callback }) {
      createCampaign(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Campaign Created',
              description: 'Campaign has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new campaign to store object
        dispatch('LOAD_CAMPAIGNS')
      })
    },
    UPDATE_CAMPAIGN({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateCampaign({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_CAMPAIGN', response.Campaign)
          Vue.prototype.$notification.success({
            message: 'Campaign Updated',
            description: 'Campaign has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeCampaignStatus(payload).then(response => {
        if (response) {
          commit('SET_CAMPAIGN', response.Campaign)
          Vue.prototype.$notification.success({
            message: (response.Campaign.Status === 2 ? 'Campaign Deleted' : 'Status Changed'),
            description: (response.Campaign.Status === 2 ? 'Campaign successfully deleted.' : 'Campaign status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getCampaignData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getCampaigns: (state) => {
      return state.list
    },
    getActiveCampaigns: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
