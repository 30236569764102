import Vue from 'vue'
import Vuex from 'vuex'
import { getPublishers, createPublisher, updatePublisher, changePublisherStatus, getPublishersForUser } from '@/api/display/publisher'
import { getPublishersShort } from '../../../api/display/publisher'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_PUBLISHER(state, payload) {
      const publisher = state.list.find(item => item.Id === parseInt(payload.Id))
      console.log(publisher, 'payload')
      Object.assign(publisher, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_PUBLISHERS({ commit, dispatch, rootState }, payload) {
      let params = null
      if (payload) {
        params = payload.PublisherId
      }
      getPublishers(params).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_PUBLISHERS_SHORT({ commit, dispatch, rootState }, payload) {
      let params = null
      if (payload) {
        params = payload.PublisherId
      }
      getPublishersShort(params).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_PUBLISHERS_FOR_USER({ commit, dispatch, rootState }, payload) {
      getPublishersForUser(payload.AccManagerId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_PUBLISHER({ commit, dispatch, rootState }, { payload, callback }) {
      createPublisher(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Publisher Created',
              description: 'Publisher has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new publisher to store object
        dispatch('LOAD_PUBLISHERS')
      })
    },
    UPDATE_PUBLISHER({ commit, dispatch, rootState }, { id, payload, callback }) {
      updatePublisher({ id, ...payload }).then(response => {
        if (response) {
          // commit('SET_PUBLISHER', response.Publisher)
          Vue.prototype.$notification.success({
            message: 'Publisher Updated',
            description: 'Publisher has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changePublisherStatus(payload).then(response => {
        if (response) {
          commit('SET_PUBLISHER', response.Publisher)
          Vue.prototype.$notification.success({
            message: (response.Publisher.Status === 2 ? 'Publisher Deleted' : 'Status Changed'),
            description: (response.Publisher.Status === 2 ? 'Publisher successfully deleted.' : 'Publisher status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getPublisher: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getPublishers: (state) => {
      return state.list
    },
    getActivePublishers: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
