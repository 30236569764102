import store from './../../store'

export default {
  install(Vue, options) {
    Vue.prototype.$nCan = (module, action) => {
      // TODO: Implement roles when available. Now its only user/admin
      return (typeof store.state.auth.role !== 'undefined' && store.state.auth.role === 1)
    }
  },
}
