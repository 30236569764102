<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft" @visibleChange="addCount">
    <div :class="$style.dropdown">
<!--      <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" />-->
      <span class="font-size-15 text-muted">
        {{ auth.firstName || 'Anonymous' }}
      </span>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <div>
          <strong>{{ $t('topBar.profileMenu.hello') }}, {{ auth.firstName || 'Anonymous' }}</strong>
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.billingPlan') }}:</strong> Professional
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.role') }}:</strong>
          {{ auth.role && auth.role===1 ? 'Admin' : 'User' }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
          {{ auth.email || '—' }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="editMyUser()">
          <i class="fe fe-user mr-2"></i>
          {{ $t('topBar.profileMenu.editProfile') }}
        </a>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="logout">
          <i class="fe fe-log-out mr-2"></i>
          {{ $t('topBar.profileMenu.logout') }}
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['auth']),
    loading() {
      return this.$store.state.auth.loading
    },
  },
  data: function () {
    return {
      count: 7,
    }
  },
  methods: {
    addCount() {
      this.count++
    },
    logout() {
      this.$store.dispatch('auth/LOGOUT')
    },
    editMyUser() {
      this.$store.dispatch('user/LOAD_USERS', { id: this.auth.id })
      this.$router.push({ name: 'user-account' }).catch(() => {})
      // this.$router.push('/user/account/')
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
