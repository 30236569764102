import Vue from 'vue'
import Vuex from 'vuex'
import { getVideoAds, createVideoAd, updateVideoAd, changeVideoAdStatus, getVideoAdsForCampaign, getVideoAdsForUserAndCampaign } from '@/api/display/videoad'
import { getVideoAdsShort } from '../../../api/display/videoad'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_AD(state, payload) {
      const ad = state.list.find(item => item.Id === parseInt(payload.Id))
      if (ad !== null && ad !== undefined) {
        Object.assign(ad, {
          ...payload,
        })
      }
    },
  },
  actions: {
    LOAD_ADS_FOR_CAMPAIGN({ commit, dispatch, rootState }, payload) {
      getVideoAdsForCampaign(payload.CampaignId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ADS_FOR_USER_CAMP({ commit, dispatch, rootState }, payload) {
      getVideoAdsForUserAndCampaign(payload.AccManagerId, payload.CampaignId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ADS({ commit, dispatch, rootState }) {
      return getVideoAds().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
        return true
      })
    },
    LOAD_ADS_SHORT({ commit, dispatch, rootState }) {
      return getVideoAdsShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
        return true
      })
    },
    CREATE_AD({ commit, dispatch, rootState }, { payload, callback }) {
      createVideoAd(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Video Ad Created',
              description: 'Video Ad has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new ad to store object
        dispatch('LOAD_ADS')
      })
    },
    UPDATE_AD({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateVideoAd({ id, ...payload }).then(response => {
        if (response) {
          commit('SET_AD', response.Videoad)
          Vue.prototype.$notification.success({
            message: 'Video Ad Updated',
            description: 'Video Ad has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeVideoAdStatus(payload).then(response => {
        if (response) {
          commit('SET_AD', response.Videoad)
          Vue.prototype.$notification.success({
            message: (response.Videoad.Status === 2 ? 'Video Ad Deleted' : 'Status Changed'),
            description: (response.Videoad.Status === 2 ? 'Video Ad successfully deleted.' : 'Video Ad status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getVideoAdData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getVideoAds: (state) => {
      return state.list
    },
    getActiveVideoAds: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
