import apiClient from '@/services/axios'
import Vue from 'vue'

const END_POINT = '/ortb/icon'

/**
 * Get icons
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getIcons(id = null) {
  const payload = id != null ? { params: { Id: parseInt(id) } } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function getIconsForIcon(VideoadId) {
  return apiClient.get(END_POINT + '/get', {
    params: { VideoadId: VideoadId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Create new Icon
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createIcon(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    VideoadId: {
      type: 'int',
      required: true,
      map: 'videoadId',
    },
    Name: {
      type: 'string',
      required: true,
      map: 'iconName',
    },
    StaticCreativeId: {
      type: 'int',
      required: false,
      map: 'staticCreativeId',
    },
    IframeUrl: {
      type: 'string',
      required: false,
      map: 'iframeUrl',
    },
    Html: {
      type: 'string',
      required: false,
      map: 'html',
    },
    Width: {
      type: 'int',
      required: true,
      map: 'width',
    },
    Height: {
      type: 'int',
      required: true,
      map: 'height',
    },
    PositionX: {
      type: 'int',
      required: false,
      map: 'positionX',
    },
    PositionY: {
      type: 'int',
      required: false,
      map: 'positionY',
    },
    Duration: {
      type: 'int',
      required: false,
      map: 'duration',
    },
    OffsetSec: {
      type: 'int',
      required: false,
      map: 'offsetsec',
    },
    Program: {
      type: 'string',
      required: true,
      map: 'program',
    },
    DestinationUrl: {
      type: 'string',
      required: true,
      map: 'destinationUrl',
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/add', { Icon: preparedData }).then(response => {
    if (response) {
      Vue.prototype.$notification.success({
        message: 'Icon Created',
        description: `Icon: ${response.data.Icon.Name} has been successfully created.`,
      })
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Icon
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateIcon(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
      map: 'videoadId',
    },
    Name: {
      type: 'string',
      required: true,
      map: 'iconName',
    },
    PositionX: {
      type: 'int',
      required: false,
      map: 'positionX',
    },
    PositionY: {
      type: 'int',
      required: false,
      map: 'positionY',
    },
    Duration: {
      type: 'int',
      required: false,
      map: 'duration',
    },
    OffsetSec: {
      type: 'int',
      required: false,
      map: 'offsetsec',
    },
    Program: {
      type: 'string',
      required: false,
      map: 'program',
    },
    DestinationUrl: {
      type: 'string',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }
  // console.log(preparedData)
  // return false
  return apiClient.post(END_POINT + '/update', { Icon: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change Icon
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeIconStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Icon: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function deleteIcon(id) {
  return apiClient.post(END_POINT + '/delete', {
    Icon: { Id: id },
  }).then(response => {
    if (response) {
      Vue.prototype.$notification.success({
        message: 'Icon Deleted',
        description: `Icon: ${response.data.Icon.Name} has been successfully deleted.`,
      })
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Icond
 *
 * @param {int} id - add id
 * @returns {Promise} - Request promise
 */
export async function cloneIcon(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Icon: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
