import apiClient from '@/services/axios'

const END_POINT = '/info'

/**
 * Get IP
 *
 * @returns {Promise} - Request promise
 */
export async function getIP() {
  return apiClient.get(END_POINT + '/ip').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get countries list
 *
 * @returns {Promise} - Request promise
 */
export async function getCountries() {
  return apiClient.get(END_POINT + '/countries').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get operating system list
 *
 * @returns {Promise} - Request promise
 */
export async function getOSs() {
  return apiClient.get(END_POINT + '/os').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get operating system list
 *
 * @returns {Promise} - Request promise
 */
export async function getBrowsers() {
  return apiClient.get(END_POINT + '/browsers').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getIabCategories() {
  return apiClient.get(END_POINT + '/iabcategories').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get servers list
 *
 * @returns {Promise} - Request promise
 */
export async function getServers() {
  return apiClient.get(END_POINT + '/servers').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
